.home-container {
  position: relative;
  text-align: center;
  padding-right: 5%;
  padding-left: 5%;
  padding-top: 7.5%;
  height: 95%;
  width: 100%;
  min-width: 100%;
  margin: 0;
  margin-top: 5%;
}

@media screen and (max-width: 500px) {
  .home-container {
    padding-top: 25%;
  }
}

.home-title {
}

.home-logo {
  width: 40%;
  max-width: 200px;
  margin: 1em 0 2em 0;
}

.home-buttons {
  margin: 0 auto;
  width: 90%;
  max-width: 450px;
}

.home-button {
  color: white;
  width: 90%;
  height: 55px;
  margin: 15px;
}

.home-button-modal {
  margin: 5px 0 0 10px;
  box-shadow: 0px 4px 0px #b81818;
}