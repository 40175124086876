@import url('https://fonts.googleapis.com/css2?family=Grandstander:wght@600&display=swap');

html {
  font-family: Grandstander;
  min-height:100%;
  overflow-y: hidden;
}

body {
  min-height:100%;
  background-color: #f46b45;
  background-image: linear-gradient(to top, #f46b45, #eea849);
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  color: white;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

p {
  font-family: Grandstander;
  font-size: 25px;
  color: white;
}

h1 {
  font-family: Grandstander;
  font-size: 50px;
  color: white;
  text-shadow: 2px 4px 4px #e8425e;
}

.alert-danger {
  position: relative;
  text-align: left;
  background-color: #e8425e;
}

.alert-heading {
  font-family: Grandstander;
  font-size: 1.75em;
  color: white;
}

.root {
  height: 100vh;
}

.container {
  position: relative;
  text-align: center;
  padding-right: 50px;
  padding-left: 50px;
  height: 95%;
  width: 100%;
  min-width: 100%;
  margin: 0;
  margin-top: 5%;
}

/* For Mobile */
@media screen and (max-width: 500px) {
  .container {
    padding-right: 5px;
    padding-left: 5px;
    margin-top: 5px;
  }
}

/* Buttons */
button {
  position: relative;
  top: 0px;
  background: #e8425e;
  box-shadow: 0px 5px 0px #b81818;
  border-radius: 3px;
  border: 1px solid transparent;
  color: white;
  margin: 0 1em;
  padding: 0.25em 1em;
  font-family: Grandstander;
  font-size: 1.5em;
  transition: All 250ms ease;
}

button:active {
  position: relative;
  box-shadow: none !important;
  top: 5px;
  transition: All 250ms ease;
}

.button-modal {
  margin: 5px 0 0 10px;
  box-shadow: 0px 5px 0px #b81818;
}

button.close {
  box-shadow: none;
}

/* Modals */
.modal-header, .card-alert {
  padding: 0.75gt6y34em;
  text-align: center;
  border: none;
}

.modal-footer {
  display: relative;
  border: none;
  padding-top: 0;
}

.modal-footer #button-submit {
  width: 50%;
  margin: 0 auto;
}

.modal-body, .card-alert {
  text-align: center;
  font-family: Grandstander;
  font-size: 1.5em;
  color: rgb(255, 255, 255);
  padding: 0.5em;
}

.modal-title, .h4 {
  font-family: Grandstander;
  font-size: 2em;
}

.modal-content {
  border: none;
  color: white;
  text-shadow: 2px 4px 5px #50505088;
  box-shadow: 2px 4px 5px #50505088;
  background-color: #f46b45;
}

.modal-input {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin-bottom: 0.1rem;
  padding: .375rem .75rem;
  font-family: Grandstander;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  outline: none;
}

.modal-input-label {
  display: inline-block;
  margin-bottom: .5rem;
  font-size: 1.2em;
}

.fade, .modal, .show {
  z-index: 9999;
}

/* Loading Spinner */
.spinner-border {
  margin-top: 45vh;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.2);
  transition: opacity 0.15s linear;
  text-align: center;
  vertical-align: middle;
}

