.game-player-name {
  margin-bottom: 0;
}

.game-player-video {
  width: 100%;
  height: 100%;
  object-position: center;
  border-radius: 20px;
  margin-bottom: -5px;
  z-index: 0;
}

.game-player-details {
  height: 100%;
  position: relative;
  border: solid black 1px;
  color: white;
  border: none;
  border-spacing: 0px;
  text-shadow: 0px 0px 15px #63afff;
  font-family: Grandstander;
  max-height: inherit;
  border-radius: 7.5px;
}

.game-player-details table {
  width: 100%;
}

.game-player-details td {
  padding: 0;
}

.game-player-panel p {
  position: absolute;
  width: 100%;
  font-size: 20px;
  z-index: 1;
}

.game-card {
  position: absolute;
  height: 58%;
  margin-top: 100px;
  padding: 0;
  margin: 0 auto;
  box-shadow: 1px 1px 5px #2a2336;
  border: none;

  @for $i from 1 through 52 {
    &:nth-child(#{$i}) {
      transform: rotate(calc(-#{random(10)}deg + #{random(10)}deg));
      right: calc(#{$i} * -15px);
      left: 10%;
      top: 27%;
    }
  }
}

/* For Mobile */
@media screen and (max-width: 500px) {
  .game-player-panel {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .game-player-panel p {
    font-size: 17px;
  }
  .game-player-name {
    width: 30%;
  }
  .game-waiting {
    font-size: 16px;
  }
}
