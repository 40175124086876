.nav-container {
  position: relative;
  width: 100%;
  height: 50px;
  background-color: #f46b45;
  color: white;
  border: none;
  border-spacing: 0px;
  padding: 5px 5px 5px 5px;
  z-index: 2000;
  animation: fadeIn 1s ease;
  box-shadow: 0px 0px 10px 5px #d44a4a;
  text-shadow: 0px 0px 4px 5px #b81818;
  font-family: Grandstander;
  font-size: 1.5em;
  z-index: 200;
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.nav-greeting {
  float: left;
}

.nav-action-buttons {
  display: flexbox;
  justify-content: space-evenly;
  float: right;
}

.nav-greeting,
.nav-room-id,
.nav-locked,
.nav-unlocked,
.nav-rules,
.nav-start,
.nav-exit {
  top: 0px;
  text-shadow: 2px 4px 5px #551c1c;
  font-size: 27px;
  transition: All 250ms ease;
  margin-left: 3px;
  margin-right: 3px;
}

.nav-locked:active,
.nav-unlocked:active,
.nav-rules:active,
.nav-start:active,
.nav-exit:active {
  flex: 1;
  box-shadow: none !important;
  top: 2px;
  transition: All 250ms ease;
}

.nav-name {
  cursor: pointer;
}

.nav-exit {
  right: 2.75em;
}

.nav-player-rules {
  text-align: center;
}
