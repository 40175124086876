.game-container {
  position: relative;
  text-align: center;
  padding-right: 4%;
  padding-left: 4%;
  padding-bottom: 4%;
  height: 92%;
  width: 100%;
  min-width: 100%;
  margin: 0;
  margin-top: 2%;
}

.game-player-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto-fill, minmax(14vh, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @media only screen 
  and (min-device-width: 350px) 
  and (max-device-width: 1000px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    grid-template-columns: repeat(4, 1fr);
  }
}

/* .game-player-container {
  display: flex;
  flex-wrap:wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 80vh;
} */

.game-info {
  padding: 0px;
}

.game-waiting {
  position: relative;
  left: auto;
  margin-bottom: 0;
}

.game-start {
  position: relative;
  font-size: 21px;
  animation: startGlow 0.7s linear infinite;
  padding: 5px 20px 1px 20px;
  margin-bottom: 5px;
}

@keyframes startGlow {
  0% {
    box-shadow: 0px 0px 10px 1px white;
  }
  25% {
    box-shadow: 0px 0px 10px 5px white;
  }
  50% {
    box-shadow: 0px 0px 10px 8px white;
  }
  75% {
    box-shadow: 0px 0px 10px 5px white;
  }
  100% {
    box-shadow: 0px 0px 10px 1px white;
  }
}

/* For Mobile */
@media screen and (max-width: 800px) {
  html {
    position: fixed;
    overflow: hidden;
    width: 100%;
  }
  .game-player-container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto-fill, minmax(11vh, 1fr));
    grid-column-gap: 10px;
    grid-row-gap: 7px;
  }
  .modal-open {
    overflow: visible !important; /* Prevents button from being cropped on mobile */
  }
}

/* React-Toastify */
.Toastify__toast {
  top: -30px;
  margin-left: 5%;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px !important;
  font-family: Grandstander;
  font-size: 22px;
  text-shadow: 2px 4px 4px #50505088;
  background: #e8425e !important;
  color: #e8425e;
  position: relative;
  z-index: 2000;
}

.Toastify__toast-container {
  position: fixed;
  max-width: 95%;
  z-index: 2000;
}

.Toastify__toast--default {
  border-radius: 5px;
  background: #e8425e;
  color: #e8425e;
  z-index: 2000;
  padding: 0;
}

.Toastify__toast-body {
  border-radius: 5px;
  background: #e8425e;
  color: #ffffff;
  z-index: 2000;
  padding: 0;
}

.Toastify__progress-bar {
  background: #e8425e;
  color: #e8425e;
  z-index: 2000;
}

.Toastify__close-button {
  box-shadow: none;
}

.toast-contents {
  margin-bottom: 10px;
  font-family: Grandstander;
}

.toast-split {
  margin: 10px 0px 10px 0px;
}